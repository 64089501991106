/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import SEO from "../components/seo"
import useScript from "../hooks/use-script"
import { Link, graphql, useStaticQuery, withPrefix } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
const Finance = (): JSX.Element => {
  useScript(withPrefix("js/show-on-scroll.js"))
  const data = useStaticQuery(graphql`
    query {
      legallyBinding: file(
        relativePath: { eq: "secured-legally-binding.jpg" }
      ) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      easyOnboarding: file(
        relativePath: { eq: "easy-onboarding-customer-service.jpg" }
      ) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      improveSales: file(relativePath: { eq: "improve-sales-operations.jpg" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eliminatePaper: file(
        relativePath: { eq: "eliminate-paper-processes.jpg" }
      ) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      acceleratePayment: file(
        relativePath: { eq: "accelerate-payment-cycle.jpg" }
      ) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      secureComplaint: file(relativePath: { eq: "secure-complaint.jpg" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        url: "https://documentesign.com/finance/",
        name:
          "Free Electronic Signature Solutions for Banking and Finance | Document eSign",
        isPartOf: {
          "@type": "WebSite",
          "@id": "https://documentesign.com/finance/#website",
        },
        datePublished: "2021-02-16T07:56:57+00:00",
        dateModified: "2021-04-28T16:44:56+00:00",
        description:
          "create, manage and automate electronic signatures approval process for banking and financial services to expedite paperwork and faster transactions with document eSign.",
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://documentesign.com/finance/"],
          },
        ],
      },
    ],
  }

  return (
    <>
      <Layout rawData={{ className: "bg-white shadow-on" }}>
        <SEO
          title="Free Electronic Signature Solutions for Banking &amp; Finance | Document eSign "
          description="create, manage and automate electronic signatures approval process for banking and financial services to expedite paperwork and faster transactions with document eSign."
          schemaMarkup={schema}
        />
        <div className="intro finance-intro bg-blue-light ani-in show-on-scroll">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 py-4 py-lg-5 ani-top">
                <h1 className="pt-1">
                  eSignature solution to streamline banking and financial
                  services
                </h1>
                <p className="mt-4 pt-1 fw-regular">
                  Seamless and paperless workflows for critical tax accounting
                  and financial services. Streamline, save, and retrieve all the
                  financial data securely in cloud storage.
                  <br />
                  <br />
                  Manage critical financial processes with Document eSign to
                  capture and share sensitive information more efficiently and
                  securely. Accelerate signature and data collection, send
                  finance agreements digitally, allow borrowers to sign from any
                  device, and keep up with the bank transactions and wealth
                  management.
                </p>
                <a
                  href="https://app.documentesign.com/auth/signup"
                  target="_blank"
                  className="btn btn-dark mt-4 mb-2 px-md-5"
                >
                  Get started
                </a>
                <br />
                <small className="text-muted">
                  <strong>Free forever,</strong> No credit card required
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lights">
          <div className="container py-5 mt-lg-4">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <Img
                    fluid={data.legallyBinding.childImageSharp.fluid}
                    alt="Secured, legally binding and auditable forms"
                    placeholderClassName="card-image-top"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-gavel"></i>
                    </div>
                    <h4>Secured, legally binding and auditable forms</h4>
                    <p className="card-text">
                      Our{" "}
                      <Link to="/free-document-esign-online/">
                        free electronic signature services{" "}
                      </Link>{" "}
                      allow you to track and maintain comprehensive audit trails
                      on all your signed documents, consent forms or agreements,
                      and maintain the sanctity of your documents, avoiding any
                      kind of fraudulent activities. A secure app like Document
                      eSign enables you to store sensitive documents with
                      advanced encryption and safe from any accidental exposure,
                      which makes them secure and legally binding.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <Img
                    fluid={data.easyOnboarding.childImageSharp.fluid}
                    alt="Easy onboarding"
                    className="card-image-top"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-users-cog"></i>
                    </div>
                    <h4>Easy onboarding &amp; customer service</h4>
                    <p className="card-text">
                      With Document eSign workflow automation, onboard the
                      interested customers by just sending your form link online
                      and get them signed with just a few clicks. Esignatures
                      save a lot of time and cost for busy customers making the
                      document signing easy, fast and effortless without the
                      need for a printer/scanner. This ensures a better user
                      experience leading to more client conversions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <Img
                    fluid={data.improveSales.childImageSharp.fluid}
                    alt="Improve sales"
                    className="card-image-top"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-chart-line"></i>
                    </div>
                    <h4>Improve sales and operations</h4>
                    <p className="card-text">
                      By collecting multiple eSignatures and data, reduce
                      operating costs and time per client with no physical
                      records to manage. Our esign service enables smooth and
                      efficient operations, focussing on the respective sales
                      and operation goal, and getting in more customers and
                      revenue for the organization.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <Img
                    fluid={data.eliminatePaper.childImageSharp.fluid}
                    alt="outdated paper processes"
                    className="card-image-top"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-file-alt"></i>
                    </div>
                    <h4>Eliminate the outdated paper processes</h4>
                    <p className="card-text">
                      Our free electronic signature service enables overwhelming
                      banking and financial firm’s process to execute operations
                      online. Feed data directly for invoice processing and
                      accelerate the billing process with reduced errors.
                      Abandon the paperwork completely and focus on identifying
                      new opportunities to serve the clients better.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <Img
                    fluid={data.acceleratePayment.childImageSharp.fluid}
                    alt="Accelerate the payment cycle"
                    className="card-image-top"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-file-invoice-dollar"></i>
                    </div>
                    <h4>Accelerate the payment cycle</h4>
                    <p className="card-text">
                      Receive timely payments and manage the payment cycle
                      quickly and efficiently. Get notified for every financial
                      transaction and use SMS codes for authentication.
                      Electronically sign and receive payments from your clients
                      simultaneously for every transaction further speeding up
                      the automation process of the payment and sales cycle.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <Img
                    fluid={data.secureComplaint.childImageSharp.fluid}
                    alt="secure and complaint"
                    className="card-image-top"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-shield-alt"></i>
                    </div>
                    <h4>Stay secure and complaint</h4>
                    <p className="card-text">
                      By 2020 the banking and finance industry will be fully
                      committed to offering e-signatures transactions on any
                      document. We adhere to compliance, rigorous global
                      security, and legal requirements to streamline
                      comprehensive audit trails as well as retention policies
                      for a secure{" "}
                      <Link to="/free-pdf-esign-online/">PDF e-sign</Link> and
                      data management solution.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Finance
